.section-emi-fd {
  @keyframes fadeEffect {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  // .loan-content.active-loan-content {
  //   display: flex;
  // }
  .loan-content {
    // display: none;
    display: flex;
    animation: fadeEffect 1s ease-in-out;
  }

  .section-emi-fd-container {
    padding: 33px 70px 42px 44px;
    background-color: $white;

    @media (max-width: 1200px) {
      padding: 33px 20px 42px 20px;
    }
    @media (max-width: 991px) {
      padding: 33px 0px;
    }
    .tab-container {
      display: flex;
      justify-content: center;
      margin-bottom: 43px;
      p {
        @media (max-width: 767px) {
          font-size: 12px;
        }
        @media (max-width: 575px) {
          font-size: 9px;
        }
      }
      .tab.tab-active {
        background-color: $secondary;
        color: $white;
      }
      .tab + .tab {
        border-left: solid 1px #e0e0e0;
      }
      .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        max-width: 180px;
        // border: solid 1px $secondary;
        background-color: #f2f2f2;
        cursor: pointer;
        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          padding: 16px 30px;
        }
        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        @media (max-width: 575px) {
          padding: 10px;
        }
      }
    }
    .box-header {
      margin-bottom: 45px;
    }

    #emi {
      .emi-fd-left {
        padding-left: 45px;
        width: 607px;
        max-width: 100%;
        @media (max-width: 1200px) {
          padding-left: 0px;
        }
        @media (max-width: 991px) {
          padding: 10px;
          margin: auto;
        }
        .emi-fd-value-container {
          padding-left: 23px;
          @media (max-width: 467px) {
            padding: initial;
            margin: auto;
          }
          .emi-fd-value-container-detail {
            .text-label-emi {
              font-size: 17px;
              line-height: 1.35;
              font-weight: 300;
              color: $text-color;
              margin-bottom: 1px;
            }
            .emi-for-flex {
              display: flex;
              align-items: center;
              column-gap: 33px;
              @media (max-width: 477px) {
                flex-direction: column;
                row-gap: 20px;
              }
              .main {
                position: relative;
                width: 356px;
                max-width: 100%;
                height: 26px;
                .slider {
                  -webkit-appearance: none;
                  appearance: none;
                  width: 100%;
                  height: 10px;
                  outline: none;
                  border-radius: 6px;
                  background-color: $small-text-color;
                  &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    width: 50px;
                    height: 50px;
                    cursor: pointer;
                    z-index: 3;
                    position: relative;
                  }
                }
                #fd-month-selector {
                  left: 33%;
                }
                .selector {
                  height: 26px;
                  width: 26px;
                  position: absolute;
                  bottom: 3px;
                  left: 50%;
                  transform: translatex(-50%);
                  z-index: 2;

                  .selectBtn {
                    height: 26px;
                    width: 26px;
                    background-color: red;
                    border-radius: 50%;
                    position: absolute;
                    bottom: 0;
                  }
                }
                #loan-selector {
                  left: 14%;
                }
                #loan-progressBar {
                  width: 14%;
                }
                .progressBar {
                  width: 50%;
                  height: 10px;
                  background-color: #29469d;
                  border-radius: 6px;
                  position: absolute;
                  top: 6px;
                  left: 0;
                }
              }
              .input-value {
                width: 162px;
                padding: 8px 15px 7px 0px;
                border-radius: 6px;
                background-color: $gray-shade-1;
                display: flex;
                justify-content: end;
                @media (max-width: 477px) {
                  align-self: flex-start;
                }
                p {
                  font-size: 17px;
                  line-height: 1.77;
                  font-weight: 400;
                  color: $text-color;
                }
                label {
                  display: flex;
                  gap: 5px;
                  padding-bottom: 2px;
                  input {
                    width: 70%;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    text-align: right;
                  }
                }
              }
            }
          }
          .loan-value-container {
            margin-bottom: 18px;
          }
          .interest-value-container {
            margin-bottom: 20px;
          }
        }
      }

      .emi-fd-right {
        width: 475px;
        max-width: 100%;
        margin-left: auto;
        border-radius: 6px;
        background-color: $gray-shade-1;
        padding-bottom: 21px;
        @media (max-width: 991px) {
          margin: 25px auto 0px;
        }
        .box-header {
          padding: 35px 35px 28px 35px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: $secondary;
          border-radius: 6px 6px 0px 0px;
          @media (max-width: 405px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
          }
          h5 {
            font-size: 20px;
            line-height: 1.33;
            font-weight: 300;
            color: $white;
            margin: 0px;
          }
          p {
            color: $white;
            font-size: 22px;
            line-height: 1.3;
            font-weight: 800;
            @media (max-width: 500px) {
              font-size: 22px;
            }
          }
        }
        .box-body {
          padding: 0px 35px;

          .box-body-flex {
            display: flex;
            justify-content: space-between;
            @media (max-width: 405px) {
              flex-direction: column;
              gap: 10px;
            }

            &:not(:last-child) {
              margin-bottom: 28px;
            }
            &:last-child {
              margin-bottom: 39px;
            }
            p {
              font-size: 17px;
              font-weight: 300;
              line-height: 1.35;
              span {
                font-weight: 800;
              }
            }
          }
          .button-container {
            width: 200px;
            margin: auto;
          }
        }
      }
    }
    #fixed {
      .emi-fd-left {
        padding-left: 45px;
        width: 607px;
        max-width: 100%;
        @media (max-width: 1200px) {
          padding-left: 0px;
        }
        @media (max-width: 991px) {
          padding: 10px;
          margin: auto;
        }
        .emi-fd-value-container {
          padding-left: 23px;
          @media (max-width: 467px) {
            padding: initial;
            margin: auto;
          }
          .emi-fd-value-container-detail {
            .text-label-emi {
              font-size: 17px;
              line-height: 1.35;
              font-weight: 300;
              color: $text-color;
              margin-bottom: 1px;
            }
            .emi-for-flex {
              display: flex;
              align-items: center;
              column-gap: 33px;
              @media (max-width: 477px) {
                flex-direction: column;
                row-gap: 20px;
              }
              .main {
                position: relative;
                width: 356px;
                max-width: 100%;
                height: 26px;
                .slider {
                  -webkit-appearance: none;
                  appearance: none;
                  width: 100%;
                  height: 10px;
                  outline: none;
                  border-radius: 6px;
                  background-color: $small-text-color;
                  &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    width: 50px;
                    height: 50px;
                    cursor: pointer;
                    z-index: 3;
                    position: relative;
                  }
                }
                #fd-month-selector {
                  left: 28%;
                }
                #month-progressBar {
                  width: 28%;
                }
                .selector {
                  height: 26px;
                  width: 26px;
                  position: absolute;
                  bottom: 3px;
                  left: 50%;
                  transform: translatex(-50%);
                  z-index: 2;

                  .selectBtn {
                    height: 26px;
                    width: 26px;
                    background-color: red;
                    border-radius: 50%;
                    position: absolute;
                    bottom: 0;
                  }
                }
                #loan-selector {
                  left: 14%;
                }
                #loan-progressBar {
                  width: 14%;
                }
                .progressBar {
                  width: 50%;
                  height: 10px;
                  background-color: #29469d;
                  border-radius: 6px;
                  position: absolute;
                  top: 6px;
                  left: 0;
                }
              }
              .input-value {
                width: 162px;
                padding: 8px 15px 7px 0px;
                border-radius: 6px;
                background-color: $gray-shade-1;
                display: flex;
                justify-content: end;
                @media (max-width: 477px) {
                  align-self: flex-start;
                }
                p {
                  font-size: 17px;
                  line-height: 1.77;
                  font-weight: 400;
                  color: $text-color;
                }
                label {
                  display: flex;
                  gap: 5px;
                  padding-bottom: 2px;
                  input {
                    width: 70%;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    text-align: right;
                  }
                }
              }
            }
          }
          .loan-value-container {
            margin-bottom: 18px;
          }
          .interest-value-container {
            margin-bottom: 20px;
          }
        }
      }

      .emi-fd-right {
        width: 475px;
        max-width: 100%;
        margin-left: auto;
        border-radius: 6px;
        background-color: $gray-shade-1;
        padding-bottom: 21px;
        @media (max-width: 991px) {
          margin: 25px auto 0px;
        }
        .box-header {
          padding: 35px 35px 28px 35px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: $secondary;
          border-radius: 6px 6px 0px 0px;
          @media (max-width: 405px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
          }
          h5 {
            font-size: 20px;
            line-height: 1.33;
            font-weight: 300;
            color: $white;
            margin: 0px;
          }
          p {
            color: $white;
            font-size: 22px;
            line-height: 1.3;
            font-weight: 800;
            @media (max-width: 500px) {
              font-size: 22px;
            }
          }
        }
        .box-body {
          padding: 0px 35px;

          .box-body-flex {
            display: flex;
            justify-content: space-between;
            @media (max-width: 405px) {
              flex-direction: column;
              gap: 10px;
            }

            &:not(:last-child) {
              margin-bottom: 23px;
            }
            &:last-child {
              margin-bottom: 39px;
            }
            p {
              font-size: 17px;
              font-weight: 300;
              line-height: 1.35;
              span {
                font-weight: 800;
              }
            }
          }
          // .button-container {
          // }
        }
      }
    }
  }
}
.trailStyle {
  max-width: 162px;
  padding: 7px 15px 7px 0px;
  border-radius: 6px;
  background-color: #e5e5e5;
  border: none;
  outline: none;
  // display: flex;
  // justify-content: end;
  text-align: right;
  height: 45px;
  &:focus-visible {
    border: none;
    outline: none;
  }
  @media (max-width: 477px) {
    align-self: flex-start;
  }
}
