// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0px;
  padding: 0px;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}

.border {
  border: 2px solid purple !important;
}

.container {
  max-width: 1254px;
}
img {
  width: 100%;
  height: auto;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body.show {
  overflow: hidden;
}

section {
  margin: 36px 0;
}
.fade-in {
  opacity: 0;
  animation: fade-in 0.8s ease-in;
  animation-fill-mode: forwards;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
