.breadcrumb {
  display: flex;
  gap: 5px;
  position: absolute;
  // top: 21px;
  left: 50%;
  bottom: -40px;
  transform: translate(-50%, 0px);
  margin-bottom: 12px;
  li {
    // color: white;
    font-size: 15px;
  }
  li + li {
    &::before {
      content: "> ";
    }
  }
}
