.services {
  // padding: 40px 52px;
  margin-top: 34px;
  margin-bottom: 30px;
  li {
    list-style: none;
  }
  .main-content {
    background-color: $white;
    padding: 40px 52px;
    @media (max-width: 575px) {
      padding: 40px 20px;
    }
  }
  .service-head {
    margin-bottom: 40px;
    text-align: center;
    max-width: 512px;
    margin-inline: auto;
    h1{
      line-height: 1.2;
    }
  }
  .tab-container {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    p {
      @media (max-width: 767px) {
        font-size: 12px;
      }
      @media (max-width: 450px) {
        font-size: 9px;
      }
    }
    .tab.tab-active {
      background-color: $secondary;
      color: $white;
    }
    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      // flex-grow: 1;
      // max-width: 296px;
      // border: solid 1px $secondary;
      background-color: #f2f2f2;
      cursor: pointer;
      &:first-child {
        border-right: solid 1px #e0e0e0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      @media (max-width: 575px) {
        padding: 10px;
      }
    }
    .tab + .tab {
      &:not(:last-child) {
        border-right: solid 1px #e0e0e0;
      }
    }
  }
  .service-content.service-content-active {
    display: flex;
    gap: 15px;
  }
  @keyframes fadeEffect {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .service-content {
    display: none;
    animation: fadeEffect 1s ease-in-out;
    transition: all 0.3s ease-in-out;
    @media (max-width: 991px) {
      flex-wrap: wrap;
      row-gap: 25px;
    }
    @media (max-width: 740px) {
      flex-wrap: wrap;
      row-gap: 25px;
    }
    @media (max-width: 454px) {
      justify-content: center;
    }
    a {
      font-size: 14px;
      text-decoration: none;
      &:hover {
        color: darken($color: $primary, $amount: 10);
      }
    }
    .wrapper {
      @media (max-width: 991px) {
        flex-basis: 48%;
      }
    }
    .service-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 30px;
      height: 100%;
      transition: all 0.3s ease-in-out;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.3) 5px 5px 20px;
      }
      @media (max-width: 991px) {
        padding: 0 30px;
      }
      @media (max-width: 740px) {
        flex-basis: 50%;
      }
      @media (max-width: 405px) {
        flex-basis: 100%;
        border-right: none;
        padding: 0;
      }
      .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-inline: auto;
        margin-bottom: 30px;
        width: 111px;
        height: 111px;
        border-radius: 50%;
        aspect-ratio: 1;
        background-color: $light-purple;
        img {
          width: 95px;
        }
      }
      .service-card-head {
        max-width: 185px;
        text-align: center;
        margin: 0px auto 20px auto;
        h3{
          text-align: center;
        }
      }
      .text-container {
        max-width: 250px;
        margin-bottom: 35px;
        color: $small-text-color;
        p{
          text-align: center;
          // text-align: justify;
        }
        @media (max-width: 767px) {
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}
