/**
 * Basic typography style for copy text
 */
body {
  // font-family: "Inter", sans-serif;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.36;
  letter-spacing: 0;
  margin: 0px;
  background-color: $body-bg-color;
  font-weight: 400;
}
p {
  font-size: 16px;
  line-height: 1.36;
  letter-spacing: 0;
  margin: 0px;
}

h1 {
  font-size: 30px;
  line-height: 1.4;
  font-weight: 400;
  letter-spacing: 0;
  color: $text-color;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 25px;
  }
}
h2 {
  font-size: 30px;
  line-height: 1.4;
  letter-spacing: 0;
  color: $text-color;
  @media (max-width: 767px) {
    font-size: 22px;
  }
}
h3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.32;
  letter-spacing: 0;
  // color: $text-color;
  color: $secondary;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
h4 {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 300;
}
h5 {
  font-size: 25px;
  line-height: 1.32;
  margin-bottom: 0;
  color: $text-color;
  @media (max-width: 991px) {
    font-size: 20px;
  }
}
a {
  font-size: 19px;
  line-height: 1.32;
  letter-spacing: 0;
  color: $primary;
  &:hover,
  &:focus,
  &:active {
    color: initial;
    text-decoration: none;
  }
}
ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.title {
  font-size: 24px;
  line-height: 1.5;
  color: $secondary;
}
.small-light-text {
  font-size: 16px;
  line-height: 1.5;
  color: $small-text-color;
}
.caption-big {
  font-size: 20px;
  line-height: 1.5;
  color: $text-color;
}
.caption-small {
  font-size: 15px;
  line-height: 1.2;
  color: $small-text-color;
}
.semi-bold-text {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  color: $text-color;
}
