// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
header {
  background-color: $white;
  .header-top {
    background-color: $primary;
    padding: 4px 0px;
    p {
      font-size: 12px;
      line-height: 1.2;
    }
    /* .search-container {
      display: flex;
      align-items: center;
      input {
        width: 0;
        // display: none;
        transform: translateX(-25px) scale(0);
      }
      &:hover {
        input {
          display: block;
          width: 100%;
        }
      }
    } */
    .header-top-left {
      display: flex;
      align-items: center;
      column-gap: 28px;
      margin-left: 67px;
      a {
        font-size: initial;
      }
      @media (max-width: 1200px) {
        margin-left: 0px;
      }
      @media (max-width: 991px) {
        justify-content: center;
      }
      .left {
        display: flex;
        column-gap: 15px;
      }
      .right {
        .text-container {
          p {
            color: $white;
          }
        }
      }
    }
    .header-top-right {
      display: flex;
      column-gap: 22px;
      float: right;
      align-items: center;

      @media (max-width: 991px) {
        display: none;
      }
      @media (max-width: 1200px) {
        justify-content: space-between;
        float: initial;
        column-gap: initial;
        .text-container {
          p {
            font-size: 12px;
         
          }
        }
      }
      .text-container {
        p {
          color: $white;
             &:hover{
              font-weight: 500;
            }
        }
      }
      .search-container {
        position: relative;
        &:hover,
        &.active {
          .input-container {
            display: block;
          }
        }
        .input-container {
          display: none;
          position: absolute;
          cursor: pointer;
          width: 200px;
          top: 100%;
          right: 0px;
          z-index: 1;

          input {
            height: 40px;
          }
        }
        .image-container {
          cursor: pointer;
        }
      }
      .enligh-nepal-container{
        display: flex;
        align-items: center;
        column-gap: 5px;
      }
    }
  }
  .header-bottom {
    position: relative;
    .icon-container {
      width: 305px;
      max-width: 100%;

      margin: 15px auto 12px auto;
      @media (max-width: 1200px) {
        margin-left: 0px;
      }
      @media (max-width: 420px) {
        width: 220px;
      }
    }
    .bottom-right {
      float: right;
      padding-right: 10px;
      width: 100%;
      .hamburger-logo-container {
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        @media (max-width: 991px) {
          display: block;
        }
      }
      .nav.show {
        @media (max-width: 991px) {
          width: 100%;
          // top: 89px;
          left: 0px;
          overflow: scroll;
          /*     .navlist {
            position: absolute;
            top: 0%;

            width: 500px;
            max-width: 100%;
            ul {
              flex-direction: column;
              li {
                color: white;
                p {
                  text-align: initial;
                  display: inline;
                  margin: initial;
                }
              }
            }
          } */
        }
        .navlist {
          // flex-direction: column;
          // align-items: center;
          // justify-content: center;
          // gap: 10px;
          // color: white;
          .navlist-items {
            // width: 200px;
            .drop-down-menu {
              position: static;
              background-color: transparent;
              .drop-down-item {
                &:hover {
                  background-color: transparent;
                }
                a {
                  color: white;
                }
              }
            }
            .sub-menu {
              position: static;
              background-color: transparent;
              .sub-item {
                border-bottom: none;
                background-color: transparent;
                &:hover {
                  background-color: transparent;
                }
                .second-sub-menu {
                  position: static;
                  background-color: transparent;
                  .second-sub-item {
                    border: none;
                    &:hover {
                      background-color: transparent;
                    }
                  }
                }
              }
            }
          }
        }

        @media (max-width: 420px) {
          width: 100%;
          height: calc(100vh - 85px);
          top: 72px;
        }
      }
      .nav {
        transition: all 0.3s ease-in-out;
        @media (max-width: 991px) {
          position: absolute;
          left: -100%;
          width: 100%;
          height: calc(100vh - 110px);
          background-color: $secondary;
          z-index: 1;
          transition: all 0.3s ease-in-out;
        }
        .navlist {
          width: 100%;
          display: flex;
          justify-content: end;
          gap: 30px;
          padding: 24px 0;
          @media (max-width: 991px) {
            padding: 20;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            color: white;
          }
          // .navlist-items.show-drop-down {
          //   .drop-down-menu {
          //     display: block;
          //   }
          //   .top-arrow {
          //     transform: rotate(180deg);
          //   }
          // }
          .navlist-items {
            position: relative;
            cursor: pointer;
            @media (max-width: 991px) {
              width: 200px;
            }
            &:hover {
              .drop-down-menu {
                display: block;
              }
              .top-arrow {
                transform: rotate(180deg);
                                  @media(max-width:991px){
                    transform: rotate(225deg);
                  }
              }
            }
          }
          p {
            font-size: 15px;
            line-height: 1.33;
            font-weight: 400;
            position: relative;
            // text-align: center;
            @media (max-width: 991px) {
              color: white !important;
            }
          }
          .sub-menu.sub-menu-small {
            width: 170px;
          }
          .sub-menu,
          .second-sub-menu {
            display: none;
            // transform: scale(0);
            position: absolute;
            left: 100%;
            top: 0px;
            background-color: $hover-color;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            // box-shadow: 0px 0px 13px -3px;
            width: 170px;
          }
          .sub-item {
            &:hover {
              background-color: darken($color: $hover-color, $amount: 10);
              .second-sub-menu {
                display: block;
                background-color: darken($color: $hover-color, $amount: 10);
              }
              .drop-down-arrow {
                .sub-menu-arrow {
                  transform: rotate(-90deg);
                }
              }
            }
          }
          .sub-item,
          .second-sub-item {
            position: relative;
            // padding: 10px 14px 10px 20px;
            cursor: pointer;
            &:not(:last-child) {
              border-bottom: 1px solid darken($color: $hover-color, $amount: 30);
            }
            .drop-down-arrow {
              padding: 10px 14px 10px 20px;
            }
          }
          .second-sub-item {
            &:hover {
              background-color: darken($color: $hover-color, $amount: 20);
            }
          }
          .drop-down-menu {
            display: none;
            position: absolute;
            top: 39px;
            left: -40px;
            background-color: white;
            // width: 170px;
            width: 225px;
            z-index: 1;
            // padding: 12px 0px;
            border-radius: 6px;
            transform-origin: top;
            transition: all 0.3s ease-in-out;
            // box-shadow: 2px 0px 13px -3px;
            a {
              display: block;
              &:hover {
                text-decoration: none;
              }
            }
            // .drop-down-item.show-sub-menu {
            //   .sub-menu {
            //     display: block;
            //   }
            //   .drop-down-arrow {
            //     .item-arrow {
            //       transform: rotate(-90deg);
            //     }
            //   }
            // }
            // .sub-item.show-second-sub-menu {
            //   background-color: darken($color: $hover-color, $amount: 10);
            //   .second-sub-menu {
            //     display: block;
            //     background-color: darken($color: $hover-color, $amount: 10);
            //   }
            //   .drop-down-arrow {
            //     .sub-menu-arrow {
            //       transform: rotate(-90deg);
            //     }
            //   }
            // }
          }
          .drop-down-item {
            position: relative;
            // padding-bottom: 10px;
            // padding: 10px 14px 10px 20px;
            &:hover {
              .sub-menu {
                display: block;
              }
              .drop-down-arrow {
                .item-arrow {
                  transform: rotate(-90deg);
                  @media(max-width:991px){
                    transform: rotate(225deg);
                  }
                }
              }
            }
            .drop-down-arrow {
              padding: 10px 14px 10px 20px;
            }
            cursor: pointer;
            &:hover {
              background-color: $hover-color;
            }
            a {
              color: black;
              font-size: 15px;
              padding: 10px 14px 10px 20px;
            }
          }
        }
        &:last-child {
          margin-right: 22x;
        }
      }
    }
  }
}

.container {
  @media (min-width: 1200px) {
    max-width: 1366px;
  }
}

.drop-down-arrow {
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    flex-grow: 1;
  }
  div {
    flex-shrink: 0;
    background-image: url("images/arrow-down.svg");
    width: 9px;
    height: 5px;
    transition: all 0.3s ease-in-out;
    background-repeat: no-repeat;
    @media(max-width:991px){
      
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  background-image: initial;
    transform: rotate(45deg);

    }
  }
}
// .drop-down-arrow {
//   &::after {
//     content: "";
//     background-image: url("images/arrow-down.svg");
//     width: 9px;
//     height: 5px;
//     position: absolute;
//     top: 40%;
//     right: -5px;
//     color: white;
//   }
// }
.unhide {
  @media (max-width: 991px) {
    display: block !important;
  }
}
/* .new-p {
  display: inline;
  font-weight: 400;
  position: relative;
  text-align: initial;
  font-size: 19px;
  line-height: 1.32;
  letter-spacing: 0;
  font-family: "Roboto", sans-serif;
} */
.body.show {
  .sticky-button {
    display: none;
  }
}



.second-bottom{
.drop-down-menu{
            background-color: white;
            width: 225px;
            z-index: 1;
            border-radius: 6px;
            transform-origin: top;
            transition: all 0.3s ease-in-out;
            ul{
              display: flex;
              flex-direction: column;
              li{
                padding: 5px; 
              }
            }
            a {
              display: block;
              &:hover {
                text-decoration: none;
              }
            }
}
}
