.section-rate-charges {
  margin-top: 30px;
  margin-bottom: 30px;
  .custome-image-container {
    max-width: 538px;
    img {
      aspect-ratio: 1.34;
    }
  }
  .rate-charges-container {
    padding: 37px 51px 36px 45px;
    background-color: $white;
    height: 100%;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
    .rate-charges-detail {
      margin-top: 38px;
      padding-left: 23px;
      .logo-container {
        width: 45px;
      }
      .arrow-container {
        width: 9px;
      }
      .common-container {
        &.active {
          .common-details {
            border-bottom: initial;
            .arrow-container {
              transform: rotate(180deg);
            }
          }
          .common-dropdown {
            display: block;
          }
        }
        .common-details {
          display: flex;
          column-gap: 20px;
          align-items: center;
          padding: 20px 0px;
          border-bottom: 1px solid $border-color;
          p {
            font-size: 16px;
            line-height: 1.3;
          }
          .arrow-container {
            transition: transform 0.3s ease;
          }
        }
        .saving-details {
          padding-top: initial;
        }

        .common-dropdown {
          display: none;
          table {
            tr {
              td {
                font-size: 17px;
                font-weight: 500;
                line-height: 1.4;
                color: $secondary;
                &:first-child {
                  padding-right: 44px;
                  padding-bottom: 8px;
                }
              }
            }
          }
        }
      }
    }
    .a-container {
      margin-top: 51px;
    }
  }
  .mobile-card {
    height: 100%;
    background-color: $white;
    padding: 34px 0px 36px 36px;
    background-repeat: no-repeat;
    background-position-x: 95.3%;
    background-position-y: 66px;
    background-size: 302px;
    @media (max-width: 767px) {
      background-position-y: 286px;
      margin-top: 20px;
    }
    .box-header {
      width: 374px;
      max-width: 100%;
      margin-bottom: 23px;
    }
    .box-body {
      .text-container {
        margin-bottom: 42px;
      }
      .image-container-collection {
        display: flex;
        column-gap: 4px;
        margin-bottom: 75px;
        .image-container {
          width: 125px;
        }
      }
    }
  }
}
