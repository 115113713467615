.sticky-button {
  visibility: hidden;
  position: fixed;
  left: 90%;
  top: 80%;
  transition: all 0.3s ease-in-out;
  li {
    list-style: none;
    line-height: 0;
    a {
      line-height: 0;
    }
  }
  .sticky-button-container {
    // position: fixed;
    position: relative;
    left: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
    @media (max-width: 991px) {
      left: 0;
    }
    @media (max-width: 575px) {
      display: none;
    }
  }
}
