.contact {
  margin: 50px 0 40px;
  .contact-container {
    background-color: $white;
    padding: 70px 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    @media (max-width: 650px) {
      justify-content: flex-start;
      row-gap: 25px;
    }
    .contact-card {
      display: flex;
      gap: 10px;
      flex-basis: 30%;
      @media (max-width: 650px) {
        flex-basis: 50%;
      }
      @media (max-width: 450px) {
        flex-basis: 100%;
        justify-content: center;
      }
      .icon-container {
        width: 50px;
        svg {
          @media (max-width: 991px) {
            height: 30px;
          }
          @media (max-width: 767px) {
            height: 25px;
          }
        }
      }
      .text-container {
        .head {
          margin-bottom: 20px;
        }
        .paragraph {
          margin-bottom: 30px;
          max-width: 270px;
        }
      }
    }
  }
}
