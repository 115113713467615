.section-gallery {
  .content {
    background-color: white;
    padding-top: 55px;
    padding-bottom: 100px;
  }
  .row-single {
    padding-bottom: 60px;
  }
  .row-multiple {
    display: flex;
    justify-content: center;
    row-gap: 60px;
    column-gap: 25px;
    flex-wrap: wrap;
    .card-person {
      flex-basis: 30%;
    }
  }
  .box-header{
    margin: 40px 0px;
    h3{
      text-align:center;
    }
    
  }
}
.card-person {
  text-align: center;
  .image-container {
    margin-inline: auto;
    width: 190px;
    margin-bottom: 8px;
  }
}
