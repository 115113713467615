.text-list {
  .header-box {
    margin-bottom: 10px;
  }
  ul {
    list-style: disc;
    padding-left: 30px;
  }
  li {
    font-size: 16px;
    line-height: 2;
    color: $list-color;
    &::marker {
      color: $primary;
      font-size: 14px;
    }
  }
}
