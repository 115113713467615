.hero-slider {
  margin: 0;
  overflow: hidden;
  // max-height: 366px;

  .image-container {
    img {
      aspect-ratio: 1366/366;
      object-fit: cover;
      object-position: initial;
    }
  }
  .slick-slider-container {
    position: relative;
    // max-height: 366px;
    ul {
      position: absolute;
      display: flex !important;
      bottom: 8.71%;
      // left: 5.49%;
      column-gap: 3px;
      justify-content: center;
      li {
        button {
          width: 7px;
          height: 7px;
          background-color: lighten($color: $secondary, $amount: 20);
          border: none;
          border-radius: 50%;
          overflow: hidden;
          margin: 0px;
          color: transparent;
          &::before {
            display: none;
          }
        }
      }
      .slick-active {
        button {
          // width: 56px;
          width: 7px;
          height: 7px;
          background-color: $secondary;
          border: none;
          border-radius: 6px;
          overflow: hidden;
          margin: 0px;
        }
      }
    }
  }
}
