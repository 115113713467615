.banner-image {
  margin-bottom: 30px;
}
.section-notice {
  padding: 20px;
  ol {
    margin-top: 25px;
  }
  a {
    font-size: 16px;
    color: #333;
    text-decoration: underline;
  }
}

.base-net-interest {
  .section-table {
    .accordion-body {
      padding: initial;
      .tables {
        padding: initial;
      }
    }
  }
}
.accordion-body {
  .download-card {
    margin-bottom: 10px;
    &:hover {
      transform: scale(1);
    }
  }
}
.display-image {
  .custome-card-misc-header {
    margin-top: 20px;
    text-align: center;
  }
  .image-container {
    display: flex;
    justify-content: center;
    max-width: 500px;
    margin: auto;
    img {
      width: initial;
      max-width: 100%;
    }
  }
}
// .facbook-sticky-button {
// }

.home-ad-overlay{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  z-index: 100;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  align-items: self-start;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  &.ad-hide{
    display: none;
  }
  .image-container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 650px;
    max-width: 100%;
    
    // .add-image{
    //   transition: all 0.5s ease-in-out;
    //   &:hover{
    //     transform: scale(1.4);
    //     transition: all 0.5s ease-in-out;
    //   }
    // } 

    .cross-container{
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      z-index: 999;
      img{
        background-color: #ff0001;
        border-radius: 50%;

        
     }
    img{
      width: initial;
      height: initial;
      max-width: 100%;
      max-height: 100%;
       
    }
  }
}
}