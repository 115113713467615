body.modal-opened {
  overflow: hidden;
  .section-branch-atm {
    .overlay {
      display: block;
    }
    .modal-image {
      display: block;
    }
  }
}
.section-branch-atm {
  margin-top: 25px;
  .custume-row {
    display: flex;
    .side-bar-container {
      max-width: 0px;
      transition: max-width 0.5s ease;
      &.show {
        max-width: 500px;
        margin-right: 20px;
      }
    }
    .map-container {
      min-height: 560px;
      overflow: hidden;
      // width: 100%;
      // height: 100%;
      // div{
      //   width: 100%;
      //   height: 100%;
      // }
    }
  }

  .view {
    display: none;
    .col-lg-4 {
      @media (max-width: 991px) {
        order: 2;
        margin-top: 20px;
      }
    }
  }
  .view.active-view {
    display: block;
  }

  .map-view {
    .address-card {
      position: relative;
      // @media (max-width: 991px) {
      //   &:last-child {
      //     display: none;
      //   }
      // }
      &::after {
        content: "";
        background-color: #ff0001;
        width: 80%;
        height: 1px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 7%;
        @media (max-width: 991px) {
          display: none;
        }
      }
    }
  }
  .card-wrapper {
    background-color: white;
    padding: 22px 10px 20px 15px;
    max-width: 396px;
    @media (max-width: 991px) {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      max-width: 100%;
    }
    @media (max-width: 982px) {
      justify-content: center;
    }
  }
  .card-row {
    background-color: white;
    padding: 33px;
    display: flex;
    // justify-content: center;
    flex-wrap: wrap;
    // gap: 3.5%;
    gap: 32px;
    .address-card {
      border: 1px solid $gray-shade-2;
      border-radius: 5px;
      flex-basis: 31%;
    }
  }
  .overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: none;
  }
  .modal-image {
    border: 10px solid white;
    max-width: 879px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    display: none;
  }
}
.address-card {
  background-color: #f2f2f2;
  padding: 18px 15px 25px;
  .small-light-text {
    color: $list-color;
  }
  .misc-link {
    margin-top: 24px;
    a {
      font-size: 15px;
    }
  }
}

.gallery-row {
  padding: 42px 38px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
  .gallery-card {
    background-color: #f2f2f2;
    cursor: pointer;
    .image-container {
      position: relative;
      .play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .share-icon {
        position: absolute;
        top: 5%;
        left: 85%;
      }
    }
    .text-container {
      padding: 14px 15px 20px;
      display: flex;
      align-items: center;
      gap: 1px;
    }
    a {
      font-size: 16px;
    }
  }
}
