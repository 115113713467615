.section-manager-card {
  margin: 25px 0;
  .content {
    background-color: white;
    padding: 38px 60px;
    @media (max-width: 640px) {
      padding: 38px 20px;
    }
  }
  .title {
    color: $secondary;
  }
  .small-light-text {
    color: $list-color;
  }
  img {
    aspect-ratio: 0.9;
    object-fit: cover;
  }
  .manager-card {
    display: flex;
    justify-content: space-between;
    @media (max-width: 600px) {
      flex-direction: column;
    }
    &:not(:last-child) {
      border-bottom: solid 1px red;
      padding-bottom: 36px;
    }
    &:not(:first-child) {
      padding-top: 45px;
    }
    .header {
      margin-bottom: 10px;
    }
    .sub-header {
      margin-bottom: 10px;
    }
    .image-container {
      max-width: 196px;
      @media (max-width: 600px) {
        order: 1;
        margin-bottom: 10px;
      }
    }
    .text-container {
      @media (max-width: 600px) {
        order: 2;
      }
    }
  }
  .officer-card {
    display: flex;
    gap: 33px;
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }
    .image-container {
      max-width: 248px;
    }
    .text-container {
      align-self: center;
    }
    .header-box {
      // margin-bottom: 30px;
    }
    .sub-header {
      // margin-bottom: 20px;
    }
  }
}
.pagination-button {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  justify-content: end;
  margin-top: 10px;
  margin-right: 10px;
  li{
    color: white;
    /* border: 2px solid black; */
    background-color: red;
    padding: 4px 10px;
    border-radius: 7px;
    a{
      color: white;
    }
  }

}
