.btn {
  &:focus {
    box-shadow: none;
  }
}

.btn-primary {
  width: 100%;
  height: 43px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  background-color: $primary;
  border: none;
  border-radius: 6px;
}
.btn-secondary {
  background-color: $secondary;
  color: white;
  border-radius: 45px;
  padding: 14px 20px;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  outline: none;
  &:hover,
  &:active,
  &:focus {
    background-color: darken($color: $secondary, $amount: 10);
    box-shadow: none;
  }
  &:active {
    &:focus {
      box-shadow: none;
    }
  }
}
.btn-tertiary {
  border: 1px solid #e0e0e0;
  background-color: transparent;
}
.btn-tertiary.active-view {
  background-color: $light-purple;
  color: $secondary;
  svg {
    path {
      fill: $secondary;
    }
  }
}
