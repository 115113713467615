.section-report {
  .card-row {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    background-color: white;
    padding: 56px 43px 70px 47px;
  }
}
.download-card {
  flex-basis: 30%;
  padding: 18px 13px;
  border: 1px solid $gray-shade-2;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  background-color: #f2f2f2;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: 991px) {
    flex-basis: 45%;
  }
  @media (max-width: 600px) {
    flex-basis: 95%;
  }
  p {
    font-size: 18px;
    line-height: 2;
  }
  a {
    display: flex;
    justify-content: space-between;
  }
}
.report-card {
  padding: 18px 13px;
  width: 271px;
  height: 193px;
  border: 1px solid $gray-shade-2;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .bottom {
    display: flex;
    justify-content: space-between;
    .misc-link {
      align-self: flex-end;
    }
  }
}
